import { Options } from 'api';

export default function checkIsFiltersSelected(
  values: Record<string, Options<unknown, string>[] | Options<unknown, string> | string | null>,
): boolean {
  return Object.values(values).some((value) => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (value !== null && typeof value === 'object' && 'label' in value) {
      return true;
    }

    if (typeof value === 'string' && value) {
      return true;
    }

    return false;
  });
}
