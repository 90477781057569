import Badge from '@atlaskit/badge';
import BitbucketPullrequestsIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import CodeIcon from '@atlaskit/icon/glyph/code';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import DocumentFilledIcon from '@atlaskit/icon/glyph/document-filled';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar';
import RecentIcon from '@atlaskit/icon/glyph/recent';
import RoadmapIcon from '@atlaskit/icon/glyph/roadmap';
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { MenuGroup } from '@atlaskit/menu';
import { SideNavigation } from '@atlaskit/side-navigation';
import { ReactElement, useMemo } from 'react';
import styled from 'styled-components/macro';

import radarIcon from 'assets/images/svg/radar.svg';
import { ROUTE_PATHS } from 'settings';
import { permissionsHandler } from 'utils';

import useLeftSidebarContentHandlers from './useLeftSidebarContentHandlers';
import { NavItem } from '../navItem';

const BadgeWrapper = styled.span`
  margin-left: 0.5rem;
`;

function LeftSidebarContent(): ReactElement {
  const { unresolvedRequestsCount, resources, unresolvedOvertimeRequestsCount } =
    useLeftSidebarContentHandlers();

  const {
    canLogTime,
    canApproveTimeSheet,
    canManageLogPeriods,
    canManageIssue,
    canViewAssociateSection,
    canViewTimeOffBalance,
    canViewSelfMeetingNotes,
    canViewMeetingNotes,
  } = useMemo(() => permissionsHandler(resources), [resources]);

  return (
    <SideNavigation label="Ya.me navigation">
      <MenuGroup>
        {canViewTimeOffBalance ? (
          <NavItem
            to={ROUTE_PATHS.TIME_OFF_BALANCE.INDEX}
            icon={<BitbucketPullrequestsIcon label="requests icon" />}
          >
            Time Off Balance
          </NavItem>
        ) : null}

        {canLogTime || canApproveTimeSheet || canManageLogPeriods || canManageIssue ? (
          <NavItem
            to={ROUTE_PATHS.TIME_TRACKING.INDEX}
            icon={<EmojiFrequentIcon label="Time Tracking icon" />}
          >
            Time Tracking
          </NavItem>
        ) : null}

        {canViewAssociateSection ? (
          <NavItem
            to={ROUTE_PATHS.ASSOCIATES.INDEX}
            icon={<UserAvatarCircleIcon label="avatar icon" />}
          >
            Associates
          </NavItem>
        ) : null}

        {resources?.pending_requests?.manage_time_off_requests ? (
          <NavItem to={ROUTE_PATHS.PENDING_REQUESTS.INDEX} icon={<CodeIcon label="code icon" />}>
            Pending Requests
            {unresolvedRequestsCount ? (
              <BadgeWrapper>
                <Badge appearance="important">{unresolvedRequestsCount}</Badge>
              </BadgeWrapper>
            ) : null}
          </NavItem>
        ) : null}
        {resources?.rolesAndPermissions ? (
          <NavItem
            to={ROUTE_PATHS.ROLES_AND_PERMISSIONS.INDEX}
            icon={<UnlockFilledIcon label="unlock icon" />}
          >
            Roles and permissions
          </NavItem>
        ) : null}
        {resources?.time_off_report ? (
          <NavItem
            to={ROUTE_PATHS.TIME_OFF_REPORT.INDEX}
            icon={<RoadmapIcon label="roadmap icon" />}
          >
            Time off report
          </NavItem>
        ) : null}

        {canViewMeetingNotes || canViewSelfMeetingNotes ? (
          <NavItem
            to={ROUTE_PATHS.NOTES_DASHBOARD.INDEX}
            icon={<CommentIcon label="Comment icon" />}
          >
            Notes dashboard
          </NavItem>
        ) : null}

        {!!resources?.surveys?.view_esat_results ||
        !!resources?.surveys?.view_list_of_all_9box_questionnaires ? (
          <NavItem to={ROUTE_PATHS.SURVEYS.INDEX} icon={<GraphBarIcon label="graph bar icon" />}>
            Surveys
          </NavItem>
        ) : null}
        {process.env.REACT_APP_ENVIRONMENT &&
        ['local', 'test'].includes(process.env.REACT_APP_ENVIRONMENT) ? (
          <NavItem
            to={ROUTE_PATHS.COMPENSATIONS.INDEX}
            icon={<DocumentFilledIcon label="document icon" />}
          >
            Compensations
          </NavItem>
        ) : null}
        {resources?.compensations ? (
          <NavItem
            to={ROUTE_PATHS.OVERTIME_UNDERTIME.INDEX}
            icon={<RecentIcon label="overtimeUndertime icon" />}
          >
            Overtime/Undertime
            {unresolvedOvertimeRequestsCount ? (
              <BadgeWrapper>
                <Badge appearance="important">{unresolvedOvertimeRequestsCount}</Badge>
              </BadgeWrapper>
            ) : null}
          </NavItem>
        ) : null}
        {resources?.risk_radar?.view_radar ? (
          <NavItem to={ROUTE_PATHS.RISK_RADAR.INDEX} icon={<img src={radarIcon} alt="radar" />}>
            Risk radar
          </NavItem>
        ) : null}
      </MenuGroup>
    </SideNavigation>
  );
}

export default LeftSidebarContent;
