// eslint-disable-next-line import/no-named-as-default
import DOMPurify from 'dompurify';

const parseAndSanitizeHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const dom = doc.documentElement.textContent;

  return dom ? DOMPurify.sanitize(dom) : '';
};

export default parseAndSanitizeHTML;
