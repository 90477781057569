// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (callBack: Function, delay: number) => {
  let timeId: number | undefined;

  return (args: unknown) => {
    if (timeId) {
      window.clearTimeout(timeId);
    }

    timeId = window.setTimeout(() => callBack(args), delay);
  };
};

export default debounce;
