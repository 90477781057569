import { Options } from 'api';

export const getActiveFilterCount = (
  filters: Record<string, Options<number, string>[] | Options<number, string> | string | null>,
): number => {
  return Object.values(filters).reduce((count, value) => {
    if (Array.isArray(value) && value.length > 0) {
      return count + 1;
    }

    if (value !== null && typeof value === 'object' && 'label' in value) {
      return count + 1;
    }

    if (typeof value === 'string' && value) {
      return count + 1;
    }

    return count;
  }, 0);
};
