import { OvertimeUndertimeRequestType } from 'features/overtimeUndertime/types';
import { ASSESS_RESULT_SORT } from 'features/surveys/constants';

import { archivedStatusLabelOptions, durationLabel } from './constants';
import { requestTypesOptionsStrategy } from './constStrategy';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type Resources = { [key: string]: any } | null;

export enum Grade {
  Trainee = 'Trainee',
  Junior = 'Junior',
  JuniorPlus = 'Junior+',
  JuniorMinus = 'Junior-',
  Middle = 'Middle',
  MiddlePlus = 'Middle+',
  MiddleMinus = 'Middle-',
  Senior = 'Senior',
  SeniorPlus = 'Senior+',
  SeniorMinus = 'Senior-',
  TechLead = 'Tech lead',
}

export enum JobTitle {
  DeliveryManager = 'Delivery Manager',
  ProjectManager = 'Project Manager',
}

export enum ContractorType {
  Delivery = 'Delivery',
  CorporateFunctions = 'Corporate functions',
}

export enum ApprovalScheme {
  ProjectManager = 'Project manager',
  DirectManager = 'Direct manager',
}

export interface ContractorEntity {
  cdID: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface Child {
  name: string;
  dob: string;
  gender: string;
}

export type Children = Child[];

interface TypeOfLeaveHistory {
  period: DatePeriod;
  typeOfLeave: string;
}

export const TypeOfAssociation = {
  associate: 'Associate',
  futureAssociate: 'Future associate',
  freelancer: 'Freelancer',
} as const;
export type TypeOfAssociation = typeof TypeOfAssociation[keyof typeof TypeOfAssociation];

export type InternalRoles = Record<string, string[]>;

export type CooperationHistory = {
  startDate: string;
  endDate: string;
  typeOfAssociation: TypeOfAssociation;
};

export interface Contractor {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  hireDate?: string;
  type?: string;
  department?: string;
  team?: string;
  position?: string;
  dismissalDate?: string;
  photo?: string;
  dob?: string;
  currentCity?: string;
  currentCountry?: string;
  hrbp?: ContractorEntity | null;
  recruiter?: ContractorEntity | null;
  jobTitle?: string;
  grade?: string;
  techGrade?: string;
  pmGrade?: string;
  pmTechGrade?: string;
  approvalScheme?: string;
  manager?: ContractorEntity | null;
  typeOfAssociation?: string;
  typeOfLeave?: string;
  englishLevel?: string;
  archived?: boolean;
  suspended?: boolean;
  jobFamily?: string;
  trialPeriod?: boolean;
  trialPeriodEndedOn?: string;
  typeOfLeavePeriod?: DatePeriod;
  typeOfLeaveHistory?: TypeOfLeaveHistory[] | null;
  children?: Children;
  personalEmail?: string;
  postalAddress?: string;
  phoneNumber?: string;
  gender?: string;
  internalRoles?: InternalRoles;
  dismissalType?: string;
  dismissalReason?: string;
  hire?: boolean;
  regrettable?: boolean;
  comment?: string;
  dismissalHistory?: DismissalHistory[];
  cooperationHistory?: CooperationHistory[];
  createDate?: string;
  updateDate?: string;
  yob?: string;
  endedCooperation: boolean;
}
export type DaysOffAmountInfo = VacationInfo | null;
export interface VacationInfo {
  left: number;
  used: number;
}

export interface DaysOffInfo {
  paidVacation: DaysOffAmountInfo;
  paidVacationLastYear: DaysOffAmountInfo;
  paidVacationNextYear: DaysOffAmountInfo;
  unpaidVacationUsed: number;
  documentedSickLeaves: DaysOffAmountInfo;
  undocumentedSickLeaves: DaysOffAmountInfo;
  additionalDaysOffUsed: number;
}

export interface ErpProjectManager {
  id: number;
  cdID: string;
  email: string;
  name: string;
}

export interface ErpProject {
  unit: string;
  name: string;
  pm: ErpProjectManager | null;
}

export interface Availability {
  status: AvailabilityStatusTypes | null;
  typeOfLeave: string | null;
  typeOfLeaveUntil: string | null;
  until: string | null;
}

export interface ErpContractor {
  daysOffInfo?: Partial<DaysOffInfo> | null;
  departmentLead?: ContractorEntity | null;
  email?: string;
  id: string;
  jobProfile?: string;
  pms?: ErpProjectManager[];
  projects?: ErpProject[];
  availability?: Availability | null;
  deliveryUnits?: string[];
}

export interface ErpContractors {
  [key: string]: ErpContractor;
}

export type BreadCrumbsList = {
  text: string;
  to: string;
}[];

export enum DayOffRequestTabsEnum {
  InProgress = 'In progress',
  Completed = 'Completed',
}

export enum ModalMode {
  VIEW = 'view',
  CREATE = 'create',
  MANAGE = 'manage',
  CREATE_BY_HR = 'hr_create',
  MANAGE_BY_HR = 'manage_by_hr',
}

export type ModalModeTypes = typeof ModalMode[keyof typeof ModalMode];

export interface Options<T, U> {
  value: T;
  label: U;
}

export enum DaysOffDuration {
  Day = 8,
  HalfDay = 4,
}

export type DaysOffDurationTypes = typeof DaysOffDuration[keyof typeof DaysOffDuration];

export enum RequestType {
  undocumentedSickLeave = 'undocumented_sick_leave',
  documentedSickLeave = 'documented_sick_leave',
  unpaidVacation = 'unpaid_vacation',
  paidVacation = 'paid_vacation',
  additionalVacation = 'additional_vacation',
}

export type RequestTypes = typeof RequestType[keyof typeof RequestType];

export interface Approver {
  id: number;
  cdID: string;
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  photo: string;
  rolesYaMe: string;
}

export interface Approval {
  approver: Approver;
  status?: ResolutionStatusTypes;
}

export type Approvals = Approval[];

export enum RequestStatus {
  in_progress = 'in_progress',
  approved = 'approved',
  declined = 'declined',
  new = 'new',
  canceled = 'canceled',
}

export const RequestStatusUI: Record<RequestStatus, string> = {
  [RequestStatus.in_progress]: 'in progress',
  [RequestStatus.approved]: 'approved',
  [RequestStatus.declined]: 'declined',
  [RequestStatus.new]: 'new',
  [RequestStatus.canceled]: 'canceled',
};

export enum CompensationRequestStatus {
  in_progress = 'in_progress',
  approved = 'approved',
  declined = 'declined',
  new = 'new',
  needs_update = 'needs_update',
}

export enum ResolutionStatus {
  in_progress = 'in progress',
  approved = 'approved',
  declined = 'declined',
  canceled = 'canceled',
}

export type ResolutionStatusTypes = 'in_progress' | 'approved' | 'declined' | 'canceled';

export type RequestStatusTypes = 'in_progress' | 'approved' | 'declined' | 'new' | 'canceled';

export type RequestStatusTypesEnum = typeof RequestStatus[keyof typeof RequestStatus];
export type RequestStatusLabelType =
  typeof requestTypesOptionsStrategy[keyof typeof requestTypesOptionsStrategy];

export type RequestStatusToApproveTypes = 'approved' | 'declined' | 'canceled';

export interface Requester {
  cdID: string;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  photo: string;
  jobFamily: string;
  team: string;
  projects: string[];
  jobProfile: string;
  type: string;
}

interface Substitute {
  id: number;
  cdID: string;
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  photo: string;
}

export interface RequestToApproveResponseSuccess {
  id: string;
  type: RequestTypes;
  duration: number;
  from: string;
  to: string;
  createdAt: string;
  approvals: Approvals;
  status: RequestStatusTypes;
  requester?: Requester;
  creator?: Requester;
  files?: string[];
  substitute: Substitute | null;
}

export type RequestsToReportResponseSuccess = RequestToApproveResponseSuccess;

export interface DaysOffRequestBody {
  type: RequestTypes | string;
  duration: DaysOffDurationTypes | string;
  from: string;
  to: string;
  files: string[];
  isWithoutResponsible: boolean;
  substituteCDID?: string;
}

export enum AvailabilityStatus {
  Workday = 'Workday',
  TimeOff = 'Time off',
  Sabbatical = 'Sabbatical',
}

export type AvailabilityStatusTypes = typeof AvailabilityStatus[keyof typeof AvailabilityStatus];

export interface ContractorListItem extends Contractor, ErpContractor {
  fullName: string;
}

export type ContractorsList = ContractorListItem[];
export enum PendingRequestsTabsEnum {
  InProgress = 'In progress',
  Completed = 'Completed',
}

export interface TimeOffBalance {
  title?: string;
  type?: RequestTypes;
  available?: number;
  used?: number;
}

const requestTypeLabelOptions = {
  paidVacation: 'Vacation',
  unpaidVacation: 'Unpaid vacation',
  undocumentedSickLeave: 'Sick leave',
  documentedSickLeave: 'Documented sick leave',
  additionalVacation: 'Additional day off',
} as const;

export type RequestTypeLabelType =
  typeof requestTypeLabelOptions[keyof typeof requestTypeLabelOptions];
export type DurationLabelType = typeof durationLabel[keyof typeof durationLabel];

export interface RequestFormData {
  type: Options<RequestTypes, RequestTypeLabelType> | null;
  duration: Options<DaysOffDurationTypes, DurationLabelType> | null;
  startDate: string;
  endDate: string;
  files?: {
    dataURL: string[];
    urlsFromRequest: string[];
  };
  isWithoutResponsible?: boolean;
  substitute?: {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    position?: string;
    photo?: string;
    label: string;
    value: string;
  } | null;
}

export interface ChangeRequestStatusRequest {
  status: RequestStatusToApproveTypes;
}

export interface EditProfileFormValues {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  type: Options<string, string> | null;
  hireDate: string;
  jobProfile: string;
  jobTitle: Options<string, string> | null;
  jobFamily: Options<string, string> | null;
  grade: Options<string, string> | null;
  techGrade: Options<string, string> | null;
  manager: Options<string, string> | null;
  recruiter: Options<string, string> | null;
  englishLevel: Options<string, string> | null;
  trialPeriod: boolean | null;
  trialPeriodEndedOn: string;
  hrbp: Options<string, string> | null;
  currentCity: string;
  currentCountry: string;
  typeOfAssociation: Options<string, string> | null;
  approvalScheme: Options<string, string> | null;
  phoneNumber: string;
  personalEmail: string;
  postalAddress: string;
  dob: string;
  children: { name: string; dob: string; gender: Options<string, string> | null }[];
  typeOfLeave: Options<string, string> | null;
  typeOfLeaveStartDate: string;
  typeOfLeaveEndDate: string;
  photo: string;
  department: Options<string, string> | null;
  team: Options<string, string> | null;
  gender: Options<string, string> | null;
  dismissalDate: string;
  archived: boolean | null;
  dismissalType: Options<string, string> | null;
  dismissalReason: Options<string, string> | null;
  hire: Options<boolean, string> | null;
  regrettable: Options<boolean, string> | null;
  comment: string;
  tenure: string;
  deliveryUnits: string;
  typeOfLeaveHistory: { typeOfLeave: Options<string, string> | null; period: DatePeriod }[] | null;
  endedCooperation: boolean;
  internalRoles: Record<string, Options<string, string>[] | Options<string, string | null>> | null;
}

export interface RequestEditContractorData {
  data: {
    firstName: Contractor['firstName'];
    lastName: Contractor['lastName'];
    position: Contractor['position'];
    type: Contractor['type'];
    jobTitle: Contractor['jobTitle'];
    grade: Contractor['grade'];
    manager: Contractor['manager'] | string;
    englishLevel: Contractor['englishLevel'];
    jobFamily: Contractor['jobFamily'];
    trialPeriod: Contractor['trialPeriod'];
    trialPeriodEndedOn: Contractor['trialPeriodEndedOn'];
    hrbp: Contractor['hrbp'] | string;
    currentCity: Contractor['currentCity'];
    currentCountry: Contractor['currentCountry'];
    typeOfAssociation: Contractor['typeOfAssociation'];
    approvalScheme: Contractor['approvalScheme'];
    phoneNumber: Contractor['phoneNumber'];
    personalEmail: Contractor['personalEmail'];
    dismissalDate: Contractor['dismissalDate'];
    postalAddress: Contractor['postalAddress'];
    gender: Contractor['gender'];
    dob: Contractor['dob'];
    children: Contractor['children'];
    typeOfLeaveInfo:
      | {
          period: DatePeriod;
          typeOfLeave: Contractor['typeOfLeave'];
        }
      | Record<string, unknown>;
    photo: Contractor['photo'];
    department: Contractor['department'];
    team: Contractor['team'];
    dismissalType: Contractor['dismissalType'];
    dismissalReason: Contractor['dismissalReason'];
    hire: Contractor['hire'] | null;
    hireDate: Contractor['hireDate'] | null;
    regrettable: Contractor['regrettable'] | null;
    comment: Contractor['comment'];
    endedCooperation: boolean;
    internalRoles: InternalRoles | null;
  };
}

export interface RequestEditContractorSuccess extends Partial<RequestEditContractorData> {
  contractorId: string;
}

export interface UploadBase64PhotoBodyRequest {
  photo: string | null;
}

export interface UploadBase64DocumentBodyRequest {
  documents: string[];
}

export interface ResponseRequestFileSuccess {
  urls: string[];
}

export interface CombinedContractorData extends Contractor, ErpContractor {
  propertiesForFilter: {
    hireDate: Contractor['hireDate'];
    type: Contractor['type'];
    department: Contractor['department'];
    team: Contractor['team'];
    dismissalDate: Contractor['dismissalDate'];
    dob: Contractor['dob'];
    currentCity: Contractor['currentCity'];
    currentCountry: Contractor['currentCountry'];
    hrbp: Contractor['hrbp'];
    grade: Contractor['grade'];
    pmGrade: Contractor['pmGrade'];
    manager: Contractor['manager'];
    typeOfAssociation: Contractor['typeOfAssociation'];
    typeOfLeave: Contractor['typeOfLeave'];
    englishLevel: Contractor['englishLevel'];
    jobFamily: Contractor['jobFamily'];
    trialPeriod: Contractor['trialPeriod'];
    trialPeriodEndedOn: Contractor['trialPeriodEndedOn'];
    children: Contractor['children'];
    childGender: string[];
    associateGender: Contractor['gender'];
    jobProfile: ErpContractor['jobProfile'];
    departmentLead: ErpContractor['departmentLead'];
    pms: ErpContractor['pms'];
    deliveryUnits: ErpContractor['deliveryUnits'];
    project: string[];
  };
  fullName: string;
}

export interface SeparatedContractorsByArchive {
  notArchived: CombinedContractorData[];
  archived: CombinedContractorData[];
  suspended: CombinedContractorData[];
  futureAssociates: CombinedContractorData[];
  roles: string[] | null;
}

export enum ContractorStatus {
  notArchived = 'notArchived',
  archived = 'archived',
  suspended = 'suspended',
  futureAssociates = 'futureAssociates',
}

export enum ASSOCIATE_TAB_IDX {
  NotArchived,
  Archived,
  Suspended,
  Future,
}

export type ContractorStatusTypes = typeof ContractorStatus[keyof typeof ContractorStatus];

export enum ContractorsTabsEnum {
  Associates = 'Associates',
  Archived = 'Archived',
  Suspended = 'Suspended',
  FutureAssociates = 'Future Associate',
}

export interface AssociatesFiltersOptions {
  childrenOptions: Options<string, string>[];
  currentCityOptions: Options<string, string>[];
  currentCountryOptions: Options<string, string>[];
  deliveryUnitsOptions: Options<string, string>[];
  departmentLeadOptions: Options<number | string, string>[];
  departmentOptions: Options<string, string>[];
  englishLevelOptions: Options<string, string>[];
  genderOptions: Options<string, string>[];
  gradeOptions: Options<string, string>[];
  hrbpOptions: Options<string, string>[];
  jobFamilyOptions: Options<string, string>[];
  jobProfileOptions: Options<string, string>[];
  managerOptions: Options<string, string>[];
  projectManagerOptions: Options<string, string>[];
  projectOptions: Options<string, string>[];
  teamOptions: Options<string, string>[];
  trialPeriodOptions: Options<string, string>[];
  typeOfAssociationOptions: Options<string, string>[];
  typeOfLeaveOptions: Options<string, string>[];
  typeOptions: Options<string, string>[];
  dob: Options<string, string>[];
}

export interface ProfileFilters {
  // TODO: types need to be corrected.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dob: [DateFieldOptionType] | string | any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dismissalDate: [DateFieldOptionType] | string | any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  hireDate: [DateFieldOptionType] | string | any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  trialPeriodEndedOn: [DateFieldOptionType] | string | any;
  associateGender: string[];
  childGender: string[];
  children: boolean[];
  currentCity: string[];
  currentCountry: string[];
  deliveryUnits: string[];
  department: string[];
  departmentLead: string[];
  englishLevel: string[];
  grade: string[];
  hrbp: string[];
  jobProfile: string[];
  jobFamily: string[];
  manager: string[];
  pms: string[];
  project: string[];
  team: string[];
  trialPeriod: string[];
  type: string[];
  typeOfAssociation: string[];
  typeOfLeave: string[];
}

export type DateFieldValuesFromRef = Pick<
  ProfileFilters,
  'hireDate' | 'dismissalDate' | 'trialPeriodEndedOn'
>;

export interface DateFieldOptionType {
  startDate?: string;
  endDate?: string;
}

export enum JobProfile {
  projectManagement = 'Project Management',
}

export interface TimeOffRequestsFiltersOptions {
  typeOptions: Options<string, string>[];
  jobFamilyOptions: Options<string, string>[];
  jobProfileOptions: Options<number, string>[];
  teamOptions: Options<string, string>[];
  projectOptions: Options<number, string>[];
  responsibleOptions: Options<string, string>[];
  requestStatusOptions: Options<string, string>[];
  requestTypesOptions: Options<string, string>[];
  archivedStatusOptions: Options<string, string>[];
}

export interface IDepartment {
  id: number;
  name: string;
  category: string;
}

export type ResponseDepartmentsSuccess = IDepartment[];

export interface DaysOffResponsible {
  id: number;
  cdID: string;
  email: string;
  firstName: string;
  lastName: string;
}

export type ResponseDaysOffResponsibleSuccess = DaysOffResponsible[];

export interface DateRangeProps {
  start_date?: string;
  end_date?: string;
}

export interface Project {
  id: number;
  name: string;
  archived: boolean;
  deliveryGroup: DeliveryGroup;
}

export interface DeliveryGroup {
  id: number;
  name: string;
}
export type ProjectsResponseSuccess = Project[];

export enum FileTypes {
  pdf = 'application/pdf',
  jpeg = 'image/jpeg',
  png = 'image/png',
}

export interface DomainsResponseSuccess {
  roles: string[];
  types: string[];
  deliveryUnits: string[];
  departments: string[];
  teams: string[];
  jobFamilies: string[];
  jobTitles: string[];
  englishLevels: string[];
  grades: string[];
  genders: string[];
  typesOfAssociation: string[];
  typesOfLeave: string[];
  approvalSchemes: string[];
  dismissalTypes: string[];
  involuntaryDismissalReasons: string[];
  voluntaryDismissalReasons: string[];
}

export interface DismissalHistory {
  dismissalReason: string;
  dismissalType: string;
  hire: boolean;
  regrettable: boolean;
  comment: string;
}

export enum DismissalType {
  Involuntary = 'Involuntary',
  Voluntary = 'Voluntary',
}

export enum ConfirmType {
  Yes = 'Yes',
  No = 'No',
}

export interface DatePeriod {
  startDate: string;
  endDate: string;
}

export interface RoleEntity {
  identifier: string;
  name: string;
}

export type YaMeRolesResponseSuccess = CdRoles[];

export interface CdRoles {
  identifier: string;
  name: string;
  description: string;
  editable: boolean;
  createDate: string;
  updateDate: string;
}

export type CdRolesResponseSuccess = CdRoles[];

export interface CreateRoleFormValues {
  name: string;
  description: string;
}

export type RequestCreateRole = Pick<CdRoles, 'name' | 'description'>;

export interface RequestEditRole {
  data: Pick<CdRoles, 'name' | 'description'>;
  cdRoleID: string;
}

export enum RolesAndPermissionsTabsEnum {
  Role = 'Role',
  Permissions = 'Permission key',
}

export interface RelationsEntity {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  photo: string;
  jobTitle: string;
}

export interface Relations {
  projectManagers: RelationsEntity[];
  projectManagerSubordinates: RelationsEntity[];
  hr: RelationsEntity | null;
  hrSubordinates: RelationsEntity[];
  manager: RelationsEntity | null;
  managerSubordinates: RelationsEntity[];
  deliveryManager: RelationsEntity | null;
  deliveryManagerSubordinates: RelationsEntity[];
}

export interface ApprovalTreeDatasource {
  id: string;
  firstName: string;
  lastName: string;
  photo: string;
  jobTitle: string;
  isTarget?: boolean;
  children?: ApprovalTreeDatasource[];
}

export enum QuestionType {
  RatingScale = 'Rating Scale',
  TextAnswer = 'Text Answer',
}

export enum QuestionTypeValues {
  RatingScale = 1,
  TextAnswer = 2,
}

export interface Question {
  title: string;
  type: Options<number, string>;
}

export interface QuestionListForm {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  questions: Question[][];
}

export interface QuestionRequest extends Omit<QuestionListForm, 'questions'> {
  questions: QuestionResponse[][];
}

export interface QuestionResponse {
  title: string;
  type: QuestionTypeValues;
}

export interface CompensationCustomFields {
  type: string;
  properties: Record<string, Record<string, unknown>>;
  required: string[];
}

export type ApprovalChain = 'admin' | 'projectManagers' | 'deliveryManager' | 'hr' | 'manager';

export interface CompensationTypesResponseSuccess {
  id: string;
  name: string;
  limit: number;
  compensated_percent: number;
  frequency_days: number;
  currency: string;
  approval_chain: ApprovalChain[];
  archived?: boolean;
  description?: string;
  is_fixed?: boolean;
  custom_fields: CompensationCustomFields | null;
}

export interface ApprovalResponseType {
  approver?: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  approver_id: string;
  comments: string;
  compensation: null;
  compensation_id: string;
  completed_at: string;
  created_at: string;
  id: string;
  reason: string;
  role: ApprovalChain;
  status: CompensationRequestApprovalStatusType;
}

export type CompensationRequestStatusType =
  | 'new'
  | 'in_progress'
  | 'approved'
  | 'declined'
  | 'needs_update';
export type CompensationRequestApprovalStatusType =
  | 'new'
  | 'pending'
  | 'approved'
  | 'declined'
  | 'needs_update'
  | 'needs_corrections';

export interface CustomFields {
  [key: string]: unknown;
}

export interface CompensationRequestsResponseSuccess {
  name: string;
  compensations: [
    {
      created_at: string;
      id: string;
      requestor_email: string;
      requestor_photo?: string;
      requestor_first_name?: string;
      requestor_last_name?: string;
      requestor_job_title?: string;
      approval_status?: CompensationRequestApprovalStatusType;
      status: CompensationRequestStatusType;
      compensation: {
        amount: number;
        compensation_type_id: string;
        currency: string;
        end_date: string;
        start_date: string;
        status: CompensationRequestStatusType;
        custom_fields: CustomFields;
      };
      compensation_type: CompensationTypesResponseSuccess;
      approvals: ApprovalResponseType[];
    },
  ];
}

export interface EsatQuestion {
  title: string;
  type: QuestionTypeValues;
  ratingAnswer: number | null;
  textAnswer: string;
}

export interface AnswerEsatRequest {
  questionnaire_id: string;
  user_id: string;
  esat_answers: EsatQuestion[][];
}

type QuestionnaireStatus = 'SCHEDULED' | 'ONGOING' | 'COMPLETED';

export interface EsatQuestionnaire {
  id: string;
  title: string;
  description: string;
  questions: QuestionResponse[][];
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  status: QuestionnaireStatus | string;
}

export type ResponseEsatQuestionnaireSuccess = EsatQuestionnaire[];

export enum SurveysTabsEnum {
  Esat = 'ESAT',
  NineBox = '9 box',
}
type NineBoxStatus = 'ONGOING' | 'COMPLETED';

export type NineBoxAssessmentPeriod = {
  year: number;
  quarterN: number;
};
export type NineBoxQuarter = {
  name: string;
  status: NineBoxStatus;
  finishAt: string;
} & NineBoxAssessmentPeriod;

export type NineBoxAssessment = {
  id: string;
  name: string;
  status: NineBoxStatus;
  canEdit: boolean;
};
export type NineBoxAssessmentFilters = {
  assessments: NineBoxAssessment[];
  associates: { userId: string; firstName: string; lastName: string }[] | null;
  departments: { id: string; name: string }[] | null;
  grades: { id: string; name: string }[] | null;
  englishLevels: string[] | null;
  quarterName: string;
};
export type NineBoxFormAssess = {
  newToAssess: boolean | null;
  performance: string | null;
  potential: string | null;
  comment: string | null;
};
export type AssessSubordinate = {
  id: string;
  name: string;
  finishAt: string;
  status: NineBoxStatus;
  subordinates: {
    userId: string;
    firstName: string;
    lastName: string;
    properties: {
      isNewToAssess: boolean;
    };
    formAssess: NineBoxFormAssess;
  }[];
};
export type Assessment = {
  assessmentName: string;
  subordinates: {
    userId: string;
    firstName: string;
    lastName: string;
    comment: string | null;
  }[];
};
export type AssessResult = {
  name: string;
  assessments: {
    [key: string]: Assessment;
  };
};

export type NineBoxAssessmentResponse = {
  userId: string;
  formAssess: NineBoxFormAssess;
};
export type AssessResultResponse = {
  [key in typeof ASSESS_RESULT_SORT[number]]: AssessResult;
};

export type ReassignContractorType = {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
};

export type OvertimeUndertimeResponseSuccess = {
  data: OvertimeUndertimeRequestType[];
  errors: Record<string, unknown>;
};

export interface ImportSpreadsheetRequest {
  sheet_id: string;
  left_top_cell: string;
  right_bottom_cell: string;
}

export interface Permission {
  key: string;
  name: string;
  editable: boolean;
  description: string;
  categoryKey: string;
  dependsOn: Permission[] | null;
  multiSelect: boolean;
}

export type ResponsePermissionsSuccess = Permission[];

export interface PermissionCategory {
  key: string;
  name: string;
}

export type ResponsePermissionCategoriesSuccess = PermissionCategory[];

export interface RolePermission {
  roleID: string;
  roleEditable: boolean;
  permissionKey: string;
  permissionEditable: boolean;
  accessLevel: string;
  multiSelect: boolean;
  createDate: string;
  updateDate: string;
  isDependence?: boolean;
  isSelectedDependence?: boolean;
}

export type ResponseRolesPermissionsSuccess = RolePermission[];

export interface UpdatedPermissionForTable {
  categoryName?: string;
  permission: Permission | null;
  rolesPermissions: RolePermission[];
}

export enum RiskRadarEnum {
  RiskRadar = 'Risk radar',
}

export interface ResponseDeliveryRiskRadarErpQuerySuccess {
  project: DeliveryAssociatesErpProject;
  user: AssociatesErpUser;
}

interface DeliveryAssociatesErpProject {
  fte: number;
  id: number;
  impact: string;
  manager: TeamProjectErpManager;
  probability: string;
  projectName: string;
}

interface AssociatesErpUser {
  editor: AssociatesErpUserEditor | null;
  firstName: string;
  id: string;
  lastName: string;
  erpID: number;
  assignmentID: number;
}

interface AssociatesErpUserEditor {
  comment: string;
  editedAt: string;
  firstName: string;
  lastName: string;
}

export interface ResponseRiskRadarCdQuerySuccess {
  nextDateReview: string | null;
  idp: string;
  hrbp: {
    id: string;
    email: string;
    name: string;
  } | null;
  mentor: { email: string; id: string; name: string } | null;
}

export interface RiskRadarProjects {
  name: string;
  managers: Contractor[] | null;
  members: ProjectAssociate[];
  deliveryGroup?: DeliveryGroup;
}

export interface ProjectAssociate {
  project?: ProjectRiskRadar;
  team?: TeamRiskRadar;
  editor: EditorRiskRadar;
  user: CombinedUserCdErp;
}

export interface ResponseCorporateRiskRadarErpSuccess {
  name: string;
  manager: TeamProjectErpManager;
  members: CorporateAssociatesErpMember[];
}

interface CorporateAssociatesErpMember {
  team: {
    impact: string;
    probability: string;
  };
  user: AssociatesErpUser;
}

interface TeamProjectErpManager {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  erpID: number;
}

export interface TeamAssociate {
  name: string;
  manager: Contractor | null;
  members: CorporateAssociatesErpMember[];
}

export enum Impact {
  NA = 'N/A',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export type ImpactTypes = typeof Impact[keyof typeof Impact];

export interface RequestUpdateAssociateRiskRadar {
  updatedAssociate: {
    probability: string;
    impact: string;
    comment: string;
    projectID?: number;
  };
  id: number;
}

export interface AssociateRowUpdate {
  probability: string;
  impact: string;
  comment: string;
  projectID?: number;
  assignmentID?: number;
}

export type ChangeAssociateType = Partial<
  Pick<AssociateRowUpdate, 'probability' | 'impact' | 'comment'>
>;

export interface RiskRadarTableAction {
  projectIdx: number | null;
  memberIdx: number | null;
}

export interface ResponseSuccess {
  message: string;
}

export type ResponseUpdateAssociateRiskRadarSuccess = ResponseSuccess;
export type ResponseQuestionnaireSuccess = ResponseSuccess;
export type ResponseEditRoleSuccess = ResponseSuccess;
export type ResponseCreateRoleSuccess = ResponseSuccess;
export type ResponseEditContractorSuccess = ResponseSuccess;

export interface ResponseRiskRadarCommentsHistory {
  user: {
    grade: string;
    name: string;
    photo: string;
  };
  history: RiskRadarCommentsHistory[];
}

export interface RiskRadarCommentsHistory {
  comment: string;
  editedAt: string;
  editorName: string;
  newImpact: string;
  newProbability: string;
  oldImpact: string;
  oldProbability: string;
  projectName?: string;
}

export interface AssociateCommentsHistoryData {
  isFetchingRiskRadarCommentsHistory: boolean;
  associateCommentsHistory: ResponseRiskRadarCommentsHistory | null;
  associateProjectName: string | null;
  erpId: number | null;
}

export enum ArchivedStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export type ArchivedStatusTypes = typeof ArchivedStatus[keyof typeof ArchivedStatus];
export type ArchivedStatusLabelType =
  typeof archivedStatusLabelOptions[keyof typeof archivedStatusLabelOptions];

interface ManageTimeOffRequestsContractor {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
  photo: string;
}

export type ManageTimeOffRequestsContractors = ManageTimeOffRequestsContractor[];
export type ManageTimeOffRequestsContractorOptions = Array<
  ManageTimeOffRequestsContractor & Options<string, string>
>;

export type AccessLevels = string[];
export type ResponseAccessLevelsSuccess = AccessLevels;

export interface RequestRolesPermissions {
  roleID: string;
  permissionKey: string;
  accessLevel: string;
}

export interface ResponseRolesPermissionsUpdateSuccess {
  message: string;
}

interface UserRiskRadar {
  cdid: string;
  id: number;
  assignmentID: number;
  firstName: string;
  lastName: string;
  photo: string;
  jobTitle: string;
  grade: string;
}

export interface ProjectRiskRadar {
  id: number;
  projectName: string;
  fte: number;
  risk: {
    probability: string;
    impact: string;
  } | null;
  manager: Pick<UserRiskRadar, 'cdid' | 'firstName' | 'lastName'> | null;
}

interface TeamRiskRadar {
  teamName: string;
  risk: {
    probability: string;
    impact: string;
  } | null;
  manager: Pick<UserRiskRadar, 'cdid' | 'firstName' | 'lastName'> | null;
}

interface EditorRiskRadar {
  firstName: string;
  lastName: string;
  comment: string;
  editedAt: string | null;
}

export interface ResponseRiskRadarErpQuerySuccess {
  user: UserRiskRadar;
  project?: ProjectRiskRadar;
  team?: TeamRiskRadar;
  editor: EditorRiskRadar;
}

export interface Notification {
  title: string;
  description: string;
  type: NotificationType;
  data?: {
    id: string;
  };
}

export enum NotificationType {
  Esat = 'Esat',
  RiskRadar = 'RiskRadar',
  NineBoxAssess = '9BoxAssessment',
  NineBoxReminder = '9BoxHrReminder',
}

export interface DateType {
  year: number;
  month: number;
}

export type TimeOfBalanceTitleType = {
  [key in RequestType]: string;
} & {
  paid_vacation_next_year: string;
  paid_vacation_last_year: string;
};

export interface RiskRadarFilters {
  jobProfile: string[];
  type: string[];
  deliveryUnit: string[];
  department: string[];
  statusOfRisk: string[];
  probabilityOfRisk: string[];
  impactOfRisk: string[];
  team: string[];
  pm: string[];
  project: string[];
  manager: string[];
  hrbp: string[];
  mentor: string[];
}

export interface RiskRadarFiltersOptions {
  jobProfile: Options<string, string>[];
  type: Options<string, string>[];
  deliveryUnit: Options<string, string>[];
  department: Options<string, string>[];
  statusOfRisk: Options<string, string>[];
  probabilityOfRisk: Options<string, string>[];
  impactOfRisk: Options<string, string>[];
  team: Options<string, string>[];
  pm: Options<string, string>[];
  project: Options<string, string>[];
  manager: Options<string, string>[];
  hrbp: Options<string, string>[];
  mentor: Options<string, string>[];
}

export interface CombinedUserCdErp extends UserRiskRadar, ResponseRiskRadarCdQuerySuccess {
  department: Contractor['department'];
  deliveryUnits: ErpContractor['deliveryUnits'];
  jobProfile: ErpContractor['jobProfile'];
  type: Contractor['type'];
  team: Contractor['team'];
  manager?: Contractor['manager'];
  pms?: ErpContractor['pms'];
}

export interface DateFilterRange {
  from: string;
  to: string;
}

export type TimeOffTransactionHistory = {
  year: number;
  [RequestType.documentedSickLeave]: TimeOffTransaction[] | [];
  [RequestType.paidVacation]: TimeOffTransaction[] | [];
  [RequestType.undocumentedSickLeave]: TimeOffTransaction[] | [];
};

export type TimeOffTransaction = {
  end_date: string;
  reason: TimeOffTransactionReason;
  start_date: string;
  sum: {
    Actual: number;
    Next: number;
    Last: number;
    Current: number;
  };
  transaction: number;
  transaction_date: string;
  comment: {
    first_name: Contractor['firstName'];
    last_name: Contractor['lastName'];
    text: string;
  };
};
type TimeOffTransactionReason = 'Canceled' | 'Approved' | 'Credited' | 'Burnt';

export type RequestTimeOffTransaction = {
  days_off_type:
    | RequestType.paidVacation
    | RequestType.documentedSickLeave
    | RequestType.undocumentedSickLeave;
  days_off_transaction_type: 'deposit' | 'withdraw';
  transaction_amount: number;
  comment: string;
};

export type NineBoxStatusAssessmentResponse = {
  assessments: NineBoxStatusAssessment[];
  quarterName: string;
};

export type NineBoxStatusAssessment = {
  name: string;
  id: string;
  responsible: { userId: string; firstName: string; lastName: string };
  status: 'Completed' | 'Not completed';
  dateOfCompletion: null | string;
  lastEdited: null | string;
  lastEditedBy: null | { userId: string; firstName: string; lastName: string };
  comment: null | string;
  isMySubordinates: boolean;
  canEdit: boolean;
};

export type CompanySettings = {
  exceptionHolidays: string[];
  exceptionWorkdays: string[];
  projectsDaysOff: {
    name: string;
    projectId: number;
  }[];
  worklogStatusList: {
    id: number;
    name: string;
    isOpen: boolean;
    isApproved: boolean;
    isPending: boolean;
  }[];
};

export interface RequestArchivedContractorSuccess {
  data: {
    archived: boolean;
  };
  contractorId: string;
}

export interface RequestManagerToReassignSuccess {
  data: {
    archived: boolean;
    managerToReassign: string;
  };
  contractorId: string;
}

export interface RequestResumeContractorSuccess {
  data: {
    suspended: boolean;
  };
  contractorId: string;
}

export interface RequestSuspendContractorSuccess {
  data: {
    suspended: boolean;
  };
  contractorId: string;
}

type NoteAssociate = {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  photo: string;
};

export type MeetingNote = {
  creator: NoteAssociate;
  date: string;
  description: string;
  id: string;
  type: NoteType;
  user: NoteAssociate;
  department: { id: number; name: string; category: string };
  team: { id: number; name: string } | null;
  projects: { id: number; name: string }[];
};

export type AssociateMeetingNote = Omit<MeetingNote, 'department' | 'team' | 'project'>;

export type NoteType = {
  id: number;
  name: 'one to one' | 'review';
};

export type NoteRequestPayload = {
  userID: string;
  typeID: NoteType['id'];
  description: string;
  date: string;
};

export type NoteTypeData = {
  noteReminders: {
    typeId: NoteType['id'];
    messages: string[];
  }[];
  noteTypes: NoteType[];
};
