import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Contractor } from 'api';
import { notify } from 'components/notification';
import { useModalHandlers, useProfileMutation } from 'hooks';

function useResumeModalHandlers() {
  const queryClient = useQueryClient();

  const [resumeContractorId, setResumeContractorId] = useState<Contractor['id']>('');
  const {
    isOpenModal: isResumeModalOpen,
    closeModal: closeResumeModal,
    openModal: openResumeModal,
  } = useModalHandlers();

  const handleResumeClick = (id: Contractor['id']): void => {
    setResumeContractorId(id);
    openResumeModal();
  };

  const { submitResumeContractorById } = useProfileMutation();

  const handleSubmitResume = () => {
    submitResumeContractorById.mutate(
      {
        data: {
          suspended: false,
        },
        contractorId: resumeContractorId,
      },
      {
        onSuccess: ({ message }) => {
          queryClient.invalidateQueries('contractors');
          queryClient.invalidateQueries('erpContractors');
          queryClient.invalidateQueries('associates');

          queryClient.invalidateQueries(['profile', resumeContractorId]);
          queryClient.invalidateQueries(['erpProfile', resumeContractorId]);

          notify({ content: message, type: 'success' });
          closeResumeModal();
        },
        onError: (error) => {
          notify({
            content: JSON.parse(error.message).message,
            type: 'error',
          });
        },
      },
    );
  };

  return {
    isResumeModalOpen,
    isResumeSubmitLoading: submitResumeContractorById.isLoading,
    closeResumeModal,
    handleResumeClick,
    handleSubmitResume,
  };
}

export default useResumeModalHandlers;
