import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Contractor } from 'api';
import { notify } from 'components/notification';
import { useProfileMutation, useProfileQuery, useErpProfileQuery, useModalHandlers } from 'hooks';
import { ROUTE_PATHS } from 'settings';

function useArchiveModalHandlers() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [contractorId, setContractorId] = useState('');

  const {
    isOpenModal: isArchiveModalOpen,
    closeModal: closeArchiveModal,
    openModal: openArchiveModal,
  } = useModalHandlers();

  const { data, isLoading: isProfileArchiveModalLoading } = useProfileQuery(contractorId, {
    enabled: !!contractorId,
  });

  const { data: erpProfileData } = useErpProfileQuery(contractorId, {
    enabled: !!contractorId,
  });

  const handleArchiveClick = (id: Contractor['id']) => {
    setContractorId(id);
    openArchiveModal();
  };

  const { submitEditContractorRequest, submitEditContractorRequestById } = useProfileMutation();

  const handleApproveClick = (): void => {
    submitEditContractorRequest.mutate(
      {
        data: {
          archived: true,
        },
        contractorId,
      },
      {
        onSuccess({ message }) {
          queryClient.invalidateQueries('contractors');
          queryClient.invalidateQueries('associates');

          queryClient.invalidateQueries(['profile', contractorId]);
          queryClient.invalidateQueries(['erpProfile', contractorId]);

          notify({ content: message, type: 'success' });
          closeArchiveModal();
        },
        onError(error) {
          notify({
            content: JSON.parse(error.message).message,
            type: 'error',
          });
        },
      },
    );
  };

  const handleReassignManagerToClick = (contractorId: string, managerToReassign: string): void => {
    submitEditContractorRequestById.mutate(
      {
        data: {
          archived: true,
          managerToReassign,
        },
        contractorId,
      },
      {
        onSuccess({ message }) {
          queryClient.invalidateQueries('contractors');
          notify({ content: message, type: 'success' });
          closeArchiveModal();
          navigate(ROUTE_PATHS.ASSOCIATES.INDEX);
        },
        onError(error) {
          notify({
            content: JSON.parse(error.message).message,
            type: 'error',
          });
        },
      },
    );
  };

  return {
    isArchiveModalOpen,
    openArchiveModal,
    closeArchiveModal,
    handleArchiveClick,
    dismissalDate: data?.dismissalDate || '',
    contractorFullName: `${data?.firstName} ${data?.lastName}`,
    jobProfile: erpProfileData?.jobProfile || '',
    handleApproveClick,
    handleReassignManagerToClick,
    isArchiveLoading: submitEditContractorRequest.isLoading,
    isProfileArchiveModalLoading,
    activeContractorId: data?.id || '',
  };
}

export default useArchiveModalHandlers;
