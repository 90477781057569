interface ParentRoute {
  INDEX: string;
}

interface AuthorizationRoutes {
  INDEX: string;
  SIGNIN: {
    INDEX: string;
    BY_ID?: string;
  };
}

interface AssociatesRoutes {
  INDEX: string;
  ADD: string;
  BY_ID: {
    INDEX: string;
    RESTORE: string;
    EDIT_PROFILE: string;
    APPROVAL_TREE: string;
  };
  IMPORT: {
    INDEX: string;
    SPREADSHEET: string;
  };
}

interface SurveysRoutes {
  INDEX: string;
  BY_ID: string;
  COMPLETE: string;
  CREATE_QUESTIONNAIRE: {
    INDEX: string;
    ESAT: ParentRoute;
  };
  NINE_BOX: {
    INDEX: string;
    ASSESS: string;
    RESULT: string;
    EDIT: string;
    ASSESSMENT_STATUS: string;
  };
}

interface TimeTrackingRoutes {
  INDEX: string;
  TABS: string;
  LOGS: {
    INDEX: string;
    BY_ID: string;
  };
  PROJECT_TASKS: ParentRoute;
  PROJECT_LOGS: ParentRoute;
  PERIOD_MANAGEMENT: ParentRoute;
}

interface OvertimeUnderTimeRoutes {
  INDEX: string;
  TABS: string;
  ASSOCIATES: ParentRoute;
  FREELANCERS: ParentRoute;
}

interface NotesDashboardRoutes {
  INDEX: string;
  IMPORT: {
    INDEX: string;
    SPREADSHEET: string;
  };
}
interface RoutePaths {
  ROOT: string;
  AUTHORIZATION: AuthorizationRoutes;
  ASSOCIATES: AssociatesRoutes;
  TIME_OFF_BALANCE: ParentRoute;
  PENDING_REQUESTS: ParentRoute;
  TIME_OFF_REPORT: ParentRoute;
  NOTES_DASHBOARD: NotesDashboardRoutes;
  COMPENSATIONS: ParentRoute;
  ROLES_AND_PERMISSIONS: ParentRoute;
  OVERTIME_UNDERTIME: OvertimeUnderTimeRoutes;
  SURVEYS: SurveysRoutes;
  IMPORT_DISMISSAL_REASONS: ParentRoute;
  RISK_RADAR: ParentRoute;
  TIME_TRACKING: TimeTrackingRoutes;
  PAYMENT_REQUIRED: ParentRoute;
  FAQ: ParentRoute;
  APP_INFO: ParentRoute;
}

const ROUTE_PATHS: RoutePaths = {
  ROOT: '/',
  AUTHORIZATION: {
    INDEX: '/authorization',
    SIGNIN: {
      INDEX: '/authorization/sign-in',
    },
  },
  ASSOCIATES: {
    INDEX: '/associates',
    ADD: '/associates/add',
    BY_ID: {
      INDEX: '/associates/:id',
      RESTORE: '/associates/:id/restore-profile',
      EDIT_PROFILE: '/associates/:id/edit-profile',
      APPROVAL_TREE: '/associates/:id/approval-tree',
    },
    IMPORT: {
      INDEX: '/associates/import',
      SPREADSHEET: '/associates/import/spreadsheet',
    },
  },
  TIME_OFF_BALANCE: {
    INDEX: '/time-off-balance',
  },
  PENDING_REQUESTS: {
    INDEX: '/pending-requests',
  },
  TIME_OFF_REPORT: {
    INDEX: '/time-off-report',
  },
  NOTES_DASHBOARD: {
    INDEX: '/notes-dashboard',
    IMPORT: {
      INDEX: '/notes-dashboard/import',
      SPREADSHEET: '/notes-dashboard/import/spreadsheet',
    },
  },
  COMPENSATIONS: {
    INDEX: '/compensations',
  },
  ROLES_AND_PERMISSIONS: {
    INDEX: '/roles-and-permissions',
  },
  OVERTIME_UNDERTIME: {
    INDEX: '/overtime-undertime',
    TABS: '/overtime-undertime/:tab',
    ASSOCIATES: {
      INDEX: '/overtime-undertime/associate',
    },
    FREELANCERS: {
      INDEX: '/overtime-undertime/freelancer',
    },
  },
  SURVEYS: {
    INDEX: '/surveys',
    BY_ID: '/surveys/:id',
    COMPLETE: '/surveys/complete',
    CREATE_QUESTIONNAIRE: {
      INDEX: '/surveys/create-questionnaire',
      ESAT: {
        INDEX: '/surveys/create-questionnaire/esat',
      },
    },
    NINE_BOX: {
      INDEX: '/surveys/nine-box',
      ASSESS: '/surveys/nine-box/assess',
      RESULT: '/surveys/nine-box/result',
      EDIT: '/surveys/nine-box/edit',
      ASSESSMENT_STATUS: '/surveys/nine-box/assessment-status',
    },
  },
  RISK_RADAR: {
    INDEX: '/risk-radar',
  },
  IMPORT_DISMISSAL_REASONS: {
    INDEX: '/import-dismissal-reasons',
  },
  TIME_TRACKING: {
    INDEX: '/time-tracking',
    TABS: '/time-tracking/:tab',
    LOGS: {
      INDEX: '/time-tracking/logs',
      BY_ID: '/time-tracking/logs/:id',
    },
    PROJECT_TASKS: {
      INDEX: '/time-tracking/project-tasks',
    },
    PROJECT_LOGS: {
      INDEX: '/time-tracking/project-logs',
    },
    PERIOD_MANAGEMENT: {
      INDEX: '/time-tracking/period-management',
    },
  },
  PAYMENT_REQUIRED: {
    INDEX: '/payment-required',
  },
  FAQ: {
    INDEX: '/faq',
  },
  APP_INFO: {
    INDEX: '/app-info',
  },
};

export default ROUTE_PATHS;
