import { Resources } from 'api';

export function permissionsHandler(resources: Resources) {
  return {
    // time_off_report
    canViewTimeOffReport: resources?.time_off_report?.view_time_off_report,
    canSuperManageTimeOffRequests: resources?.time_off_report?.super_manage_time_off_requests,
    // pending_requests
    canManageTimeOffRequests: resources?.pending_requests?.manage_time_off_requests,
    canCancelDocumentedSickLeaveAdditionalVacations:
      resources?.pending_requests?.cancel_documented_sick_leave_additional_vacations,
    // associates
    canViewAssociateManagersAndHrbp: resources?.associates?.managers_hrbp,
    canViewAssociateProject: resources?.associates?.project,
    canViewTimeOffBalance: !!resources?.associates?.view_time_off_balance,
    canViewAssociateGeneralInfo: !!resources?.associates?.general_info,
    canViewAssociateSection: !!resources?.associates?.view_associate_section,
    canCreateEditAssociateBasic: !!resources?.associates?.create_and_edit_contractor_basic,
    canEditAssociateAdvanced: !!resources?.associates?.edit_contractor_advanced,
    canViewManageAssignRoles: !!resources?.associates?.assign_contractor_roles,
    canViewAssociateDismissalDetails: !!resources?.associates?.dismissal_details,
    canViewAssociateDismissalDate: !!resources?.associates?.dismissal_date,
    canViewAssociateTypeOfAssociation: !!resources?.associates?.type_of_association,
    canViewAssociateRecruiter: !!resources?.associates?.recruiter,
    canViewAssociateContactInfo: !!resources?.associates?.contact_info,
    canViewAssociatePostalAddress: !!resources?.associates?.postal_address,
    canViewAssociateBirthday: !!resources?.associates?.birthday,
    canViewAssociateChildrenInfo: !!resources?.associates?.children_info,
    canViewAssociateTypeOfLeave: !!resources?.associates?.type_of_leave,
    canViewAssociateTypeOfLeaveHistory: !!resources?.associates?.see_type_of_leave_history,
    canViewAssociateTrialPeriod: !!resources?.associates?.trial_period,
    canViewAssociateTrialPeriodEndedOn: !!resources?.associates?.trial_period_ended_on,
    canViewAssociateGender: !!resources?.associates?.gender_of_associate,
    canViewAssociateJobDetails: !!resources?.associates?.job_details,
    canViewAssociateApprovalSchemeFiled: !!resources?.associates?.approval_scheme,
    canViewAssociateGrade: !!resources?.associates?.grade,
    canViewAssociateTechGrade: !!resources?.associates?.tech_grade,
    canViewPmGrade: !!resources?.associates?.pm_grade,
    canViewPmTechGrade: !!resources?.associates?.pm_tech_grade,
    canViewAssociateEnglishLevel: !!resources?.associates?.english_level,
    canViewAssociatesArchivedAndSuspendedList: !!resources?.associates?.view_archived_list,
    canViewAssociatesFutureProfiles: !!resources?.associates?.view_future_associates_profiles,
    canViewCooperationHistory: !!resources?.associates?.cooperation_history,

    // overtime/undertime
    canUndoOvertimesUndertimes: resources?.compensations?.undo_overtimes_undertimes,

    // transaction_history
    canViewTimeOffTransactionHistory:
      resources?.transaction_history?.view_time_off_transaction_history,
    canChangeTimeOffTransactionHistory:
      resources?.transaction_history?.change_time_off_transaction_history,

    // surveys
    // esat
    canCreateEsat: resources?.surveys?.create_esat_questionnaire,
    canViewEsatResults: resources?.surveys?.view_esat_results,
    // 9box
    canViewList9box: resources?.surveys?.view_list_of_all_9box_questionnaires,
    canAssess9box: resources?.surveys?.assess_9box_questionnaire,
    canViewResults9box: resources?.surveys?.view_results_9box_questionnaire,
    canEditResults9box: resources?.surveys?.edit_results_9box_questionnaire,
    canViewAllStatus9box: resources?.surveys?.status_9box_questionnaire,
    canViewFiltersResults9box: resources?.surveys?.use_filters_9box_questionnaire,
    canDownload9boxResults: resources?.surveys?.download_9box_results,

    // time tracking
    canLogTime: !!resources?.time_tracking?.log_time,
    canManageIssue: !!resources?.time_tracking?.manage_tasks,
    canDeleteIssue: !!resources?.time_tracking?.delete_tasks,
    canManageLogPeriods: !!resources?.time_tracking?.manage_log_periods,
    canViewApprovalLog: !!resources?.time_tracking?.view_approval_log,

    // time tracking - project logs tab - filter options
    canManageAllProjects: !!resources?.time_tracking?.manage_all_projects,
    canManageMyDeliveryProjects: !!resources?.time_tracking?.manage_my_delivery_projects,
    canManageAssignedProject: !!resources?.time_tracking?.manage_assigned_project,
    canApproveTimeSheet: !!(
      resources?.time_tracking?.manage_all_projects ||
      resources?.time_tracking?.manage_my_delivery_projects ||
      resources?.time_tracking?.manage_assigned_project
    ),
    canExportMonthlyLoggedTimeReport: resources?.time_tracking?.export_logged_time,
    canExportProjectLoggedTimeReport: resources?.time_tracking?.export_project_logged_time,

    //notes
    canViewMeetingNotes: !!resources?.notes?.view_notes,
    canViewSelfMeetingNotes: !!resources?.notes?.view_self_created_notes,
    canCreateMeetingNotes: !!resources?.notes?.create_notes,
  };
}
