import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { NoteTypeData } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useNoteTypesDataQuery(
  options?: UseQueryOptions<NoteTypeData, Error>,
): UseQueryResult<NoteTypeData, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<NoteTypeData, Error>(
    ['note-types-data'],
    async () => {
      const response = await authorisedFetch('/note_type_data', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useNoteTypesDataQuery;
